import React, { useEffect, useRef, useState } from "react";

import "../../Assets/Css/Home.css";
import { Col, Modal, Row } from "reactstrap";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";
const InquiryFormMedical = () => {
  const [selectedValue, setSelectedValue] = useState("");

  // Handler function to update the selected value
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [device, setDevice] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    device: "",
    message: "",
  });
  const onMedicalDevice = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("product", "Medical Device");
    formData.append("device", device);
    formData.append("message", message);

    try {
      const response = await axios({
        method: "post",
        url: `${Config.apiUrl}medical_devices`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (validateForm()) {
          swal({
            icon: "success",
            text: "Medical Device Inquiry Send Successfully",
          }).then((data) => {
            setLoading(false);
            setName("");
            setEmail("");
            setPhone("");
            setDevice("");
            setMessage("");
            window.location.reload();
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "First name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!phone) {
      newErrors.phone = "Phone number is required";
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!device) {
      newErrors.device = "Medical device is required";
    }

    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      {isLoading && <Loader />}
      <form onSubmit={onMedicalDevice} className=" px-3 contact-form">
        <Row className="input-field ">
          <Col>
            <div className="mt-4">
              <label htmlFor="">
                Name <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter Name"
                className="contact-field"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors.name && (
                <span className="text-danger">{errors.name}</span>
              )}
            </div>
          </Col>
          <Col>
            <div className="mt-4">
              <label htmlFor="">
                {" "}
                Email Address <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="email"
                placeholder="Enter Email Address"
                className="contact-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && (
                <span className="text-danger">{errors.email}</span>
              )}
            </div>
          </Col>
          <Col>
            <div className="mt-4">
              <label htmlFor="">
                {" "}
                Phone Number <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter Phone Number"
                className="contact-field"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              {errors.phone && (
                <span className="text-danger">{errors.phone}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="input-field ">
          <Col>
            <div className="mt-4">
              <label htmlFor="">
                {" "}
                Products <span className="text-danger">*</span>{" "}
              </label>
              <select
                id="myDropdown"
                value={selectedValue}
                onChange={handleSelectChange}
                className="contact-field"
                required
              >
                <option>Medical Device</option>
              </select>
            </div>
          </Col>
          <Col>
            <div className="mt-4">
              <label htmlFor="">
                {" "}
                Medical Devices <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter Medical Devices"
                className="contact-field"
                value={device}
                onChange={(e) => setDevice(e.target.value)}
                required
              />
              {errors.device && (
                <span className="text-danger">{errors.device}</span>
              )}
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="input-field">
          <Col>
            <div className="mt-4 mb-4">
              <label htmlFor="">
                {" "}
                Message <span className="text-danger">*</span>{" "}
              </label>
              <textarea
                type="text"
                placeholder="Enter Your Query"
                rows={3}
                className="contact-field"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              {errors.message && (
                <span className="text-danger">{errors.message}</span>
              )}
            </div>
          </Col>
        </Row>
        <div className="modal-footer">
          <div className="">
            <button className="button bg-green border-0" aria-labelledby="message">
              <i className="bi bi-send me-3"></i>
              Send Message
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default InquiryFormMedical;
