import React, { useEffect, useRef, useState } from "react";

import "../../Assets/Css/Home.css";
import { Col, Row } from "reactstrap";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";

const ProductDetailForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [product, setProduct] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    product: "",
    message: "",
  });
  const onInquiry = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("product", product);
    formData.append("message", message);

    try {
      const response = await axios({
        method: "post",
        url: `${Config.apiUrl}contact`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (validateForm()) {
          swal({
            icon: "success",
            text: "Thank You! Product Inquiry Sent Successfully",
          }).then((data) => {
            window.location.reload();
            setLoading(false);
            setName("");
            setEmail("");
            setPhone("");
            setProduct("");
            setMessage("");
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "First name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!phone) {
      newErrors.phone = "Phone number is required";
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!product) {
      newErrors.product = "Product is required";
    }

    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const [subc, setSubc] = useState([]);
  const onSub = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}product_subcategory`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        console.log(response);
        setSubc(response.data.subc);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    onSub();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <h2 className="fw-bold my-4 text-center">Inquiry Now</h2>
      <form onSubmit={onInquiry} className="mt-4 contact-form">
        <Row className="input-field ">
          <Col>
            <div className="mt-4">
              <input
                type="text"
                placeholder="Enter Name"
                className="contact-field"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors.name && (
                <span className="text-danger">{errors.name}</span>
              )}
            </div>
          </Col>
          <Col>
            <div className="mt-4">
              <input
                type="email"
                placeholder="Enter Email Address"
                className="contact-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && (
                <span className="text-danger">{errors.email}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="input-field ">
          <Col>
            <div className="mt-4">
              <input
                type="text"
                placeholder="Enter Phone Number"
                className="contact-field"
                value={phone}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              {errors.phone && (
                <span className="text-danger">{errors.phone}</span>
              )}
            </div>
          </Col>
          <Col>
            <div className="mt-4">
              <select
                className="contact-field"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                required
              >
                <option>-- Select Product --</option>
                {subc.map((sub, i) => {
                  return (
                    <option value={sub.name} key={i}>
                      {sub.name}
                    </option>
                  );
                })}
              </select>
              {errors.product && (
                <span className="text-danger">{errors.product}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="input-field ">
          <Col>
            <div className="mt-4">
              <textarea
                type="text"
                placeholder="Enter Your Query"
                rows={4}
                className="contact-field"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              {errors.message && (
                <span className="text-danger">{errors.message}</span>
              )}
            </div>
          </Col>
        </Row>
        <div className="mt-4 d-flex justify-content-center">
          <button className="button bg-green border-0" aria-labelledby="message">
            <i className="bi bi-send me-3"></i>
            Send Message
          </button>
        </div>
      </form>
    </>
  );
};

export default ProductDetailForm;
