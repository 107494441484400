import React, { useEffect, useRef, useState } from "react";
import "../../Assets/Css/Home.css";
import { Form, Link } from "react-router-dom";
import { Col, Modal, Row } from "reactstrap";
import InquiryFormMedical from "./InquiryFormMedical";
import ReactPaginate from "react-paginate";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";

const MedicalDevice = () => {
  const sliderRef = useRef();
  const [page, setPage] = useState(1);
  const [enquiry_modal, setenquiry_modal] = useState(false);
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  document.title = "Medical Device | Rajveer Impex";

  useEffect(() => {
    onBusiness();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const onBusiness = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}medical_device`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setData(response.data.device_page.medical_device);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (selected === Math.ceil(data.length / 16) - 1) {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">Medical Device</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}`} className="text-dark text-decoration-none">
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Medical Device
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container-box py-md-4">
          <div className="row">
            <div className="col-md-12">
              <div
                className="p-4 bg-white"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <div className="mb-5">
                  <div className="row">
                    {data.slice(page * 16 - 16, page * 16).map((product, i) => {
                      return (
                        <div className="col-md-3" id="product-image" key={i}>
                          <Link
                            className="text-dark text-decoration-none"
                            onClick={(e) => {
                              if (!product.isContent) {
                                e.preventDefault();
                                setenquiry_modal(true);
                              }
                            }}
                          >
                            <div className="medical-card text-center mt-4">
                              <img
                                src={`${Config.imageUrl}medical/${product.image}`}
                                className="w-100 medical-image"
                                alt="medical device"
                                loading="lazy"
                              />
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex pagination">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(data.length / 16)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={enquiry_modal}
        size="lg"
        centered
        className="medicalDevice-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Inquiry Form</h5>
          <button
            type="button"
            aria-labelledby="message"
            onClick={() => {
              setenquiry_modal(false);
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <InquiryFormMedical />
      </Modal>
    </>
  );
};

export default MedicalDevice;
