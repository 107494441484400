import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";
import Client from "../Elements/Client";
const BusinessDollars = () => {
  document.title = "Business In Dollars | Rajveer Impex";
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  const [openIndex, setOpenIndex] = useState(0);
  const handleAccordionClick = (index) => {
    setOpenIndex(index === openIndex ? -1 : index);
  };
  useEffect(() => {
    onBusiness();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [businessHeading, setBusinessHeading] = useState([]);
  const [businessSubheading, setBusinessSubheading] = useState([]);
  const [businessSubtitle, setBusinessSubtitle] = useState([]);
  const [businessImage, setBusinessImage] = useState([]);
  const [certificateImage, setCertificateImage] = useState([]);
  const [certificateHeading, setCertificateHeading] = useState([]);
  const [certificateSubtitle, setCertificateSubtitle] = useState([]);
  const [eximTitle, setEximTitle] = useState([]);
  const [eximSubtitle, setEximSubtitle] = useState([]);
  const [chooseTitle, setChooseTitle] = useState([]);
  const [chooseSubtitle, setChooseSubtitle] = useState([]);
  const [data, setData] = useState([]);
  const [client, setClient] = useState([]);
  const onBusiness = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}business_dollar`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setBusinessHeading(
          response.data.business_dollar_page.business_intro
            .businessintro_heading
        );
        setBusinessSubheading(
          response.data.business_dollar_page.business_intro
            .businessintro_subheading
        );
        setBusinessSubtitle(
          response.data.business_dollar_page.business_intro
            .businessintro_subtitle
        );
        setBusinessImage(
          response.data.business_dollar_page.business_intro.businessintro_bg
        );
        setCertificateImage(
          response.data.business_dollar_page.business_certificate
            .businesscerti_bg
        );
        setCertificateHeading(
          response.data.business_dollar_page.business_certificate
            .businesscerti_heading
        );
        setCertificateSubtitle(
          response.data.business_dollar_page.business_certificate
            .businesscerti_subtitle
        );
        setEximTitle(
          response.data.business_dollar_page.business_exim.exim_heading
        );
        setEximSubtitle(
          response.data.business_dollar_page.business_exim.exim_subtitle
        );
        setChooseTitle(
          response.data.business_dollar_page.business_choose.choose_title
        );
        setChooseSubtitle(
          response.data.business_dollar_page.business_choose.choose_subtitle
        );
        setData(response.data.business_dollar_page.business_choose_us);
        setClient(response.data.business_dollar_page.business_client);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">Business In Dollars</h1>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-dark text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Business In Dollars
            </p>
          </div>
        </div>
      </section>

      <section className="bg-light pt-5">
        <div className="container-box py-md-5">
          <div
            className="row align-items-center about-section"
            id="bussiness-section"
          >
            <div className="col-md-6">
              <img
                src={`${Config.imageUrl}${businessImage}`}
                className="w-90"
                alt="business"
                loading="lazy"
              />
            </div>
            <div className="col-md-6 bussiess-doller">
              <h2 className="mb-4">{businessHeading}</h2>
              <h4 className="mb-4">{businessSubheading}</h4>

              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: businessSubtitle,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container-box py-md-5">
          <div className="row align-items-center bussiess-doller">
            <div className="col-md-7">
              <h3 className="mb-4 iso-certi">{certificateHeading}</h3>

              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: certificateSubtitle,
                  }}
                />
              </p>
            </div>

            <div
              className="col-md-5 ps-md-5 text-end position-relative"
              id="certificate"
            >
              <img
                src={`${Config.imageUrl}${certificateImage}`}
                className="w-90"
                alt="certificate"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container-box py-md-5">
          <div className="row align-items-center about-section bussiess-doller">
            <div className="col-md-12">
              <h4 className="mb-4">{eximTitle}</h4>
              <div className="content">
                <p className="align-items-center d-flex mb-0 lh-sm exim-club">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eximSubtitle,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-box py-md-5">
          <div className="row bussiess-doller">
            <div className="col-md-6">
              <h2 className="fw-bold mb-4 text-dark">{chooseTitle}</h2>
              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: chooseSubtitle,
                  }}
                />
              </p>
            </div>

            <div className="col-md-6 ps-md-5 ">
              <div className="product-desc">
                <div className="">
                  <ul className="accordian-element ps-0">
                    {data.map((obj, i) => (
                      <li key={i}>
                        <input
                          type="radio"
                          name="accordion-element"
                          id={`accordion-${i}`}
                          checked={i === openIndex}
                          onChange={() => handleAccordionClick(i)}
                        />
                        <label htmlFor={`accordion-${i}`}>{obj.title}</label>

                        <div
                          className={`content ${i === openIndex ? "open" : ""}`}
                        >
                          <p className="align-items-center d-flex mb-0 lh-sm">
                            {obj.subtitle}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-gray bg-client">
        <div className="container-box pb-5">
          <div className="top-heading">
            <h4 className="fw-bold mb-4 text-dark">Our Client</h4>
          </div>
              <Client client={client}/>
        </div>
      </section>
    </>
  );
};

export default BusinessDollars;
