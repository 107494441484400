import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import swal from "sweetalert";
import Loader from "./Loader";
import { Config } from "../../Config";

const Footer = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
  });
  const onSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("email", email);
    try {
      const response = await axios({
        method: "post",
        url: `${Config.apiUrl}subscribe`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (validateForm()) {
          swal({
            icon: "success",
            text: "Subscribe Successfully",
          }).then((data) => {
            setLoading(false);
            setEmail("");
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  useEffect(() => {
    onContactDetail();
  }, []);
  const [contactAddress, setContactAddress] = useState([]);
  const [contactPhone, setContactPhone] = useState([]);
  const [contactEmail, setContactEmail] = useState([]);
  const [longitude, setLongitude] = useState([]);
  const [courseSubtitle, setCourseTitle] = useState([]);
  const [knowledgebaseSubtitle, setKnowTitle] = useState([]);
  const onContactDetail = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}contact_detail`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setContactAddress(
          response?.data?.contact_page?.contact_info?.contact_addresses
        );
        setContactPhone(
          response?.data?.contact_page?.contact_info?.contact_numbers
        );
        setContactEmail(
          response?.data?.contact_page?.contact_info?.contact_mails
        );
        setLongitude(response?.data?.contact_page?.contact_info?.longitude);
        setCourseTitle(
          response?.data?.contact_page?.contact_info?.course_subtitle
        );
        setKnowTitle(
          response?.data?.contact_page?.contact_info?.knowledgebase_subtitle
        );
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <footer className="footer">
        <div className="bg-overlay"></div>
        <div className="container-box pt-5 pb-5 border-secondary cta-section">
          <div className="row">
            <div className="col-md-9 text-white">
              <h3 className="display-5 fw-bold ">Have Questions?</h3>
              <p className="fs-5 mt-3 mb-4">
                Please feel free to ask any questions you may have!
              </p>
            </div>

            <div className="col-md-3">
              <div className="about-card text-white cta-btn">
                <Link
                  to={`${Config.appUrl}contact-us`}
                  className="button bg-green text-light border-0"
                >
                  Get In Touch
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-box">
          <div className="pb-5">
            <div className="row footer-link">
              <div className="col-md-2 mt-5">
                <h3 className="fw-bold text-green">Useful Links</h3>
                <div className="mt-4 footer-links">
                  <Link to={`${Config.appUrl}`}>Home</Link>
                  <Link to={`${Config.appUrl}about-us`}>About Us</Link>
                  <Link to={`${Config.appUrl}contact-us`}>Contact Us</Link>
                  <Link to={`${Config.appUrl}blogs`}>Blogs</Link>
                </div>
              </div>
              <div className="col-md-2 mt-5">
                <h3 className="fw-bold text-green">Categories</h3>
                <div className="mt-4 footer-links">
                  <a href={`${Config.appUrl}products#1`}>Hygiene Product</a>
                  <a href={`${Config.appUrl}products#2`}>Medical Product</a>
                  <a href={`${Config.appUrl}medical-device`}>Medical Devices</a>
                </div>
              </div>

              <div className="col-md-4 mt-5">
                <h3 className="fw-bold text-green">Contact Us</h3>
                <div className="mt-4 footer-links">
                  <div className="d-flex text-white">
                    <i className="bi bi-geo-alt me-3"></i>
                    <p className="text-white" style={{ fontSize: "16.5px" }}>
                      {" "}
                      Addministrative : {contactAddress}
                    </p>{" "}
                  </div>
                  <p
                    className="text-white mail-second"
                    style={{ fontSize: "16.5px" }}
                  >
                    {" "}
                    Work : {courseSubtitle}
                  </p>{" "}
                  <div className="text-white d-flex">
                    <i className="bi bi-whatsapp me-3"></i>
                    <a href={`https://wa.me/${contactPhone}`} target="_blank">
                     +91 {contactPhone} /{" "}
                    </a>
                    <a href={`https://wa.me/${longitude}`} target="_blank">
                      &nbsp;+91 {longitude}
                    </a>
                  </div>
                  <div className="text-white d-flex">
                    <i className="bi bi-envelope me-3"></i>{" "}
                    <a href={`mailto:${contactEmail}`}> {contactEmail}</a>
                    <br />
                  </div>
                  <a
                    href={`mailto:${knowledgebaseSubtitle}`}
                    className="mail-second"
                  >
                    {" "}
                    {knowledgebaseSubtitle}
                  </a>
                </div>
              </div>

              <div className="col-md-4 mt-5">
                <h3 className="fw-bold text-green mb-4">Subscribe Now</h3>
                <form onSubmit={onSubscribe}>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control rounded-0 px-4 py-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                  <div>
                    {" "}
                    <button
                      className="button bg-green text-light mt-4"
                      style={{ border: "1px solid #fff" }}
                      aria-labelledby="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className=" container-box d-flex justify-content-between py-4 border-top border-secondary">
          <div className="text-white">
            &copy; Copyrights 2024 - Rajveer Impex - Website development
          </div>
          <div className="policy-terms text-white">
            Designed & Developed by
            <a
              href="https://www.zithas.com/"
              target="_blank"
              className="text-decoration-none text-green"
            >
              {" "}
              Zithas Technologies{" "}
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
