import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "../../Assets/Css/About.css";
import aboutInquiry from "../../Assets/Images/inquiryImg.jpeg";
import ProductForm from "../Products/ProductForm";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";
const About = () => {
  const sliderRef = useRef();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  document.title = "About Us | Rajveer Impex";
  const certificateSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    onBusiness();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [aboutHeading, setAboutHeading] = useState([]);
  const [aboutImage, setAboutImage] = useState([]);
  const [aboutSubtitle, setAboutSubtitle] = useState([]);
  const [data, setData] = useState([]);
  const [gallery, setGallery] = useState([]);
  const onBusiness = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}aboutus`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setAboutHeading(
          response.data.about_page.about_intro.aboutintro_heading
        );
        setAboutImage(response.data.about_page.about_intro.intro_bg);
        setAboutSubtitle(
          response.data.about_page.about_intro.aboutintro_subtitle
        );
        setData(response.data.about_page.about_product);
        setGallery(response.data.about_page.about_gallery);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box d-flex justify-content-center align-items-end">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4 mt-4 pt-1">About Us</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}`} className="text-dark text-decoration-none">
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              About Us
            </p>
          </div>
        </div>
      </section>
      <section className="bg-gray">
        <div className="container-box py-md-5">
          <div className="row align-items-center about-section" id="title-web">
            <div className="col-md-6">
              <h2 className="mb-4">{aboutHeading}</h2>

              <p className="paragraph">
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutSubtitle,
                  }}
                />
              </p>
            </div>

            <div className="col-md-6 ps-md-5 text-end position-relative">
              <img
                src={`${Config.imageUrl}${aboutImage}`}
                className="w-100"
                alt="about"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="container-box pt-5 pb-md-5 inquiry-back bg-white">
          {data.map((obj, i) => {
            return (
              <div className="mb-5 d-flex" id="bg-productCare">
                <img
                  src={`${Config.imageUrl}${obj.image}`}
                  className="about-category-image"
                  alt="product"
                  loading="lazy"
                />
                <div className="ms-5" id="bd-productContent">
                  <h5 className="fw-bold fs-3 text-dark mb-3">{obj.title}</h5>
                  <p className="paragraph">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: obj.description,
                      }}
                    />
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className="bg-gray">
        <div className="container-box py-md-5" id="title-web">
          <div className="top-heading">
            <h5>Gallery</h5>
            <h2>Our Gallery</h2>
            <h2></h2>
          </div>

          <div className="row mt-4">
            <Slider ref={sliderRef} {...certificateSettings}>
              {gallery.map((obj, i) => {
                return (
                  <div key={i}>
                    <div className="certi-img">
                      <img
                        src={`${Config.imageUrl}members/${obj.image}`}
                        className="w-90"
                        alt="certificate"
                        loading="lazy"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container-box py-md-5">
          <div className="row align-items-center" id="title-web">
            <div className="col-md-6 aboutInquiry">
              <img src={aboutInquiry} className="w-100" loading="lazy" alt="inquiry" />
            </div>
            <div className="col-md-6">
              <ProductForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
