import React, { useRef } from "react";
import "../../Assets/Css/Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Config } from "../../Config";

const Certificate = ({ certificate = [] }) => {
  const sliderRef = useRef(null);

  const certificateSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="row mt-4">
        <Slider {...certificateSettings}>
          {certificate.map((certi, i) => {
            return (
              <div key={i}>
                <div className="certi-img">
                  <img
                    src={`${Config.imageUrl}members/${certi.image}`}
                    className="w-90"
                    height={"475px"}
                    alt="certificate"
                    loading="lazy"
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default Certificate;
