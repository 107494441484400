import React, { useRef } from "react";
import Slider from "react-slick";
import { Config } from "../../Config";
const Client = ({ client = [] }) => {
  const sliderRef = useRef();
  const clientSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="row mt-4">
        <Slider ref={sliderRef} {...clientSettings}>
          {client.map((allClient, i) => {
            return (
              <div key={i}>
                <div className="certi-img">
                  <img
                    src={`${Config.imageUrl}partners/${allClient.image}`}
                    className="w-90"
                    height={"336px"}
                    alt="client"
                    loading="lazy"
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default Client;
