import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ProductForm from "./ProductForm";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";

const Products = () => {
  document.title = "Products | Rajveer Impex";
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    onSubcategory();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const onSubcategory = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}product`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setSubCategories(response.data.product_page.product_subcategory);
        setData(response.data.product_page.product_subcategory);
        setCategory(response.data.product_page.product_category);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const onHandleSearch = (e) => {
    setSubCategories(
      data.filter((product) =>
        product.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const [currentHash, setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">All Products</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}`} className="text-dark text-decoration-none">
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              All Products
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container-box py-md-4">
          <div className="row" id="main-product">
            <div className="col-md-4" id="filter-product">
              <div
                className="p-4 bg-white"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <div className="row align-items-center">
                  <label htmlFor="" className="fs-5 mb-2">
                    Filter Products
                  </label>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control h-100 rounded-0 py-2 px-4 fs-5"
                      placeholder="Search.."
                      onChange={(e) => onHandleSearch(e)}
                    />
                  </div>
                </div>
              </div>

              <div
                className="p-4 bg-white mt-4"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <h4 className="fw-bold mb-4">CATEGORY</h4>
                {categories.map((category, i) => {
                  return (
                    <a
                      href={`#${category.id}`}
                      key={category.id}
                      className={`border-0 w-100 text-start fs-5 py-2 px-3 border-bottom text-category ${
                        currentHash === `#${category.id}` ? "text-product" : ""
                      }`}
                    >
                      {" "}
                      {category.name}
                    </a>
                  );
                })}
              </div>

              <div
                className="p-4 bg-white mt-4 bg-productinquiry"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <ProductForm />
              </div>
            </div>

            <div className="col-md-8" id="filter-product">
              <div
                className="p-4 bg-white"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <div className="mb-5 product">
                  <div className="row">
                    {subCategories.map((subCategory, i) => {
                      if (location.hash.slice(1) == subCategory.category_id) {
                        return (
                          <div key={i} className="col-md-4" id="product-image">
                            <Link
                              to={subCategory.slug}
                              className="text-dark text-decoration-none"
                            >
                              <div className="product-card text-center mt-4">
                                <img
                                  src={`${Config.imageUrl}product/subcategories/${subCategory.image}`}
                                  className="w-75"
                                  alt="product"
                                  loading="lazy"
                                />
                                <div className="mt-3 px-2 py-3 px-3">
                                  <h5 className="fw-bold">
                                    {subCategory.name}
                                  </h5>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      }
                    })}
                    {subCategories?.length === 0 ? (
                      <div className="no-product">No Product Available</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className="p-4 bg-white mt-5 product-inquiry"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <ProductForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
