import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Home Page/Header";
import Footer from "./Components/Home Page/Footer";
import Home from "./Components/Home Page";
import ProductDetails from "./Components/Products/ProductDetails";
import About from "./Components/About/About";
import ContactUs from "./Components/ContactUs/ContactUs";
import Blogs from "./Components/Blogs/Blogs";
import BlogDetails from "./Components/Blogs/BlogDetails";
import Products from "./Components/Products/Products";
import Daiper from "./Components/Products/Diaper";
import Certificate from "./Components/Certificate/Certificate";
import BusinessDollars from "./Components/Business/BusinessDollars";
import MedicalDevice from "./Components/Products/MedicalDevice";
import { Config } from "./Config";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path={`${Config.appUrl}`} element={<Home />} />
          <Route path={`${Config.appUrl}about-us`} element={<About />} />
          <Route path={`${Config.appUrl}contact-us`} element={<ContactUs />} />
          <Route path={`${Config.appUrl}products`} element={<Products />} />
          <Route path={`${Config.appUrl}products/:slug`} element={<Daiper />} />
          <Route path={`${Config.appUrl}product-details`} element={<ProductDetails />} />
          <Route path={`${Config.appUrl}product-details/:slug`} element={<ProductDetails />} />
          <Route path={`${Config.appUrl}medical-device`} element={<MedicalDevice />} />
          <Route path={`${Config.appUrl}certificate`} element={<Certificate />} />
          <Route path={`${Config.appUrl}business-in-dollars`} element={<BusinessDollars />} />
          <Route path={`${Config.appUrl}blogs`} element={<Blogs />} />
          <Route path={`${Config.appUrl}blog/:slug`} element={<BlogDetails />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
