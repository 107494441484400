import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import headerLogo from "../../Assets/Images/ssmpl-logo.jpeg";
import "../../Assets/Css/Home.css";
import { Config } from "../../Config";

const InnerLinks = ({ setMobileMenu, linkData, name }) => {
  const [innerLinks, setInnerLinks] = useState(false);

  return (
    <li>
      <Link
        to={linkData.link}
        onClick={() => setInnerLinks(!innerLinks)}
        className="d-flex justify-content-between align-items-center py-0"
        style={{ cursor: "pointer" }}
      >
        {name}
        <button className="ps-4 py-2 bg-transparent text-end border-0 text-white" aria-labelledby="message">
          <i className={`bi bi-caret-${innerLinks ? "up" : "down"}`}></i>
        </button>
      </Link>
      <ul
        className={`mobile-inner-links d-block ${
          innerLinks ? "d-block" : "d-none"
        }`}
      >
        {linkData.map((sublink, i) => (
          <li key={i}>
            {sublink.sublinks ? (
              <InnerInnerLinks
                setMobileMenu={setMobileMenu}
                linkData={sublink}
                name={sublink.label}
              />
            ) : (
              <NavLink onClick={() => setMobileMenu(false)} to={sublink.link}>
                {sublink.label}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </li>
  );
};

const InnerInnerLinks = ({ setMobileMenu, linkData, name }) => {
  const [innerLinks, setInnerLinks] = useState(false);

  return (
    <li>
      <Link
        to={linkData.link}
        onClick={() => setMobileMenu(false)}
        className="d-flex justify-content-between align-items-center pe-0"
        style={{ cursor: "pointer" }}
      >
        {name}
        <button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setInnerLinks(!innerLinks);
          }}
          className="py-2 px-3 bg-transparent text-end border-0 text-white"
          aria-labelledby="message"
        >
          <i className={`bi bi-caret-${innerLinks ? "up" : "down"}`}></i>
        </button>
      </Link>
      <ul
        className={`mobile-inner-links d-block ${
          innerLinks ? "d-block" : "d-none"
        }`}
      >
        {linkData.sublinks.map((sublink, i) => (
          <li key={i}>
            <NavLink onClick={() => setMobileMenu(false)} to={sublink.link}>
              <i className="bi bi-caret-right-fill me-1"></i>
              {sublink.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

const Header = () => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const productLinks = [
    {
      label: "Personal Hygiene Product",
      link: `${Config.appUrl}products#1`,
      sublinks: [
        {
          label: "Sanitary Pads",
          link: `${Config.appUrl}products/sanitary-pad`,
        },
        {
          label: "Diaper",
          link: `${Config.appUrl}products/diaper`,
        },
        {
          label: "Under Pads",
          link: `${Config.appUrl}products/under-pad`,
        },
        {
          label: "Maternity Pads",
          link: `${Config.appUrl}products/maternity-pad`,
        },
        {
          label: "Soap",
          link: `${Config.appUrl}products/soap`,
        },
        {
          label: "Undersheet",
          link: `${Config.appUrl}products/undersheet`,
        },
        {
          label: "Dispenser",
          link: `${Config.appUrl}products/dispenser`,
        },
      ],
    },
    {
      label: "Medical Product",
      link: `${Config.appUrl}products#2`,
      sublinks: [
        {
          label: "Ultra Sound Gel",
          link: `${Config.appUrl}products/ultra-sound-gel`,
        },
        {
          label: "Medical Wipes",
          link: `${Config.appUrl}products/medical-wipes`,
        },
        {
          label: "Probe Cover",
          link: `${Config.appUrl}products/probe-cover`,
        },
        {
          label: "Surgical",
          link: `${Config.appUrl}products/surgical`,
        },
        {
          label: "Condoms",
          link: `${Config.appUrl}products/condoms`,
        },
        {
          label: "Gloves",
          link: `${Config.appUrl}products/gloves`,
        },
      ],
    },
    {
      label: "Medcal Devices",
      link: `${Config.appUrl}medical-device`,
    },
    {
      label: "Fragrance Personalisation",
      link: `${Config.appUrl}products#3`,
    },
  ];

  return (
    <>
      <nav className={``}>
        <div
          className={`container-box py-2 navibar ${
            stickyNavbar ? "white-navbar" : ""
          }`}
          id="rajveer-impex"
        >
          <div className="">
            <NavLink to={`${Config.appUrl}`} className="logo">
              <img src={headerLogo} className="w-90" loading="lazy" alt="logo" />
            </NavLink>
          </div>

          <div className="d-flex justify-content-end w-100">
            <ul className="navlinks ms-5">
              <li>
                <NavLink to={`${Config.appUrl}`}>Home</NavLink>
              </li>
              <li>
                <NavLink to={`${Config.appUrl}about-us`}>About Us</NavLink>
              </li>
              <li>
                <a className="">
                  {" "}
                  Products <i className="ps-1 bi bi-chevron-down"></i>
                  <ul className="inner-links">
                    <li>
                      <NavLink to={`${Config.appUrl}products#1`}>
                        Personal Hygiene Product
                        <ul className="inner-inner-links">
                          <li>
                            <NavLink
                              to={`${Config.appUrl}products/sanitary-pad`}
                            >
                              Sanitary Pad
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/diaper`}>
                              Diaper
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/under-pad`}>
                              Under Pad
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}products/maternity-pad`}
                            >
                              Maternity Pad
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/soap`}>
                              Soap
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/undersheet`}>
                              Undersheet
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/dispenser`}>
                              Dispenser
                            </NavLink>
                          </li>
                        </ul>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}products#2`}>
                        Medical Product
                        <ul className="inner-inner-links">
                          <li>
                            <NavLink
                              to={`${Config.appUrl}products/ultra-sound-gel`}
                            >
                              {" "}
                              Ultra Sound Gel
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}products/medical-wipes`}
                            >
                              Medical Wipes
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${Config.appUrl}products/probe-cover`}
                            >
                              Probe Cover (Vaginal)
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/surgical`}>
                              Surgical
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/condoms`}>
                              Condoms
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${Config.appUrl}products/gloves`}>
                              Gloves
                            </NavLink>
                          </li>
                        </ul>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}medical-device`}>
                        Medical Devices
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`${Config.appUrl}products#3`}>
                      Fragrance Personalisation
                      </NavLink>
                    </li>
                    <i className="bi bi-caret-up-fill"></i>
                  </ul>
                </a>
              </li>
              <li>
                <NavLink to={`${Config.appUrl}business-in-dollars`}>
                  The Business In Dollars
                </NavLink>
              </li>
              <li>
                <NavLink to={`${Config.appUrl}certificate`}>
                  Certificate
                </NavLink>
              </li>
              <li>
                <NavLink to={`${Config.appUrl}contact-us`}>Contact Us</NavLink>
              </li>
            </ul>
          </div>
        </div>
        
        {/* Mobile menu ============================================================== */}
        <div
          className={`container-box py-2 navibar ${
            stickyNavbar ? "white-navbar" : ""
          }`}
          id="rejveer-mobile"
        >
          <div className="dispalay-n d-xs-block">
            <div>
              <NavLink to={`${Config.appUrl}`}>
                <img src={headerLogo} alt="logo" className="mobile-logo" loading="lazy" />
              </NavLink>
              <button
                className="navbar-toggler mt-3 ms-2 mb-3 mobile-btn"
                aria-labelledby="message"
                onClick={() => setMobileMenu(true)}
              >
                <span className="navbar-toggler-icon text-dark fs-2"> ☰</span>
              </button>
            </div>
          </div>
          <div className={`mobile-menu ${mobileMenu && "showMenu"}`}>
            <ul className="mobile-navlinks ps-0 pt-2">
              <div className="d-flex justify-content-end mb-5">

                <h2
                  onClick={() => setMobileMenu(false)}
                  className="menu-btn text-center px-3 py-2 me-2 mt-2 rounded-circle text-white"
                  style={{ backgroundColor: "#ffffff17", cursor: "pointer" }}
                >
                  &times;
                </h2>
              </div>
              <li>
                <NavLink onClick={() => setMobileMenu(false)} to={`${Config.appUrl}`}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMobileMenu(false)}
                  to={`${Config.appUrl}about-us`}
                >
                  About Us
                </NavLink>
              </li>
              <InnerLinks
                setMobileMenu={setMobileMenu}
                linkData={productLinks}
                name="Product"
              />

              <li>
                <NavLink
                  onClick={() => setMobileMenu(false)}
                  to={`${Config.appUrl}business-in-dollars`}
                >
                  Bussiness In Dollars
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMobileMenu(false)}
                  to={`${Config.appUrl}certificate`}
                >
                  Certificate
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setMobileMenu(false)}
                  to={`${Config.appUrl}contact-us`}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
