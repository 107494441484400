import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import "../../Assets/Css/Products.css";
import ProductDetailForm from "../ProductDetail/ProductDetailForm";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";

const ProductDetails = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const sliderRef = useRef();

  const productSettings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 4,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };
  const params = useParams();
  const formattedSlug = params.slug.replace(/-/g, " ").toLowerCase();

  const capitalizedSlug =
    formattedSlug.charAt(0).toUpperCase() + formattedSlug.slice(1);
  document.title = `${capitalizedSlug} | Rajveer Impex`;

  useEffect(() => {
    onProduct();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const onProduct = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}product_detail/${params.slug}`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setProduct(response.data.product);
        setProductImage(response.data.product.slider_image);
        setMainImage(response.data.product.feature_image);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">{product.title}</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}`} className="text-dark text-decoration-none">
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              {product.title}
            </p>
          </div>
        </div>
      </section>

      {/* First Section */}
      <section className="py-5">
        <div className="container-box pt-md-5">
          <div className="row">
            <div className="col-md-6">
              <div className="product-main-img bg-white text-center border py-5">
                <img
                  src={`${Config.imageUrl}product/featured/${mainImage}`}
                  alt="product"
                  loading="lazy"
                />
              </div>

              <div className="row border border-top-0 mx-0">
                <Slider {...productSettings} className="px-0">
                  {productImage.map((img, i) => (
                    <div key={i} className="col-3">
                      <div
                        className={`d-flex py-4 bg-white border-end justify-content-center ${
                          product.feature_image === img.image && "current-img"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => setMainImage(img.image)}
                      >
                        <img
                          src={`${Config.imageUrl}product/featured/${img.image}`}
                          className="small-product-img"
                          alt="product"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div className="col-md-6 ps-md-5" id="filter-product">
              <div className="product-desc">
                <h2 className="fw-bold mb-4 text-dark">{product.title}</h2>
                <div className="content">
                  <p className="align-items-center d-flex mb-0 lh-sm exim-club">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.description,
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" style={{ background: "#f8f9fa" }}>
        <div className="container-box pb-md-5">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <ProductDetailForm />
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
