import React, { useEffect, useRef, useState } from "react";
import "../../Assets/Css/Home.css";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";
const BlogComment = () => {
  const [errors, setErrors] = useState({
    blogName: "",
    blogEmail: "",
    blogWebsite: "",
    blogMessage: "",
  });
  const [blogName, setBlogName] = useState("");
  const [blogEmail, setBlogEmail] = useState("");
  const [blogWebsite, setBlogWebsite] = useState("");
  const [blogMessage, setBlogMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const onBlogComment = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("blog_name", blogName);
    formData.append("blog_email", blogEmail);
    formData.append("blog_website", blogWebsite);
    formData.append("blog_message", blogMessage);

    try {
      const response = await axios({
        method: "post",
        url: `${Config.apiUrl}blog_comment`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (validateForm()) {
          swal({
            icon: "success",
            text: "Thank You! Blog Comment Sent Successfully",
          }).then((data) => {
            window.location.reload();
            setLoading(false);
            setBlogName("");
            setBlogEmail("");
            setBlogWebsite("");
            setBlogMessage("");
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!blogName) {
      newErrors.blogName = "Name is required";
    }

    if (!blogEmail) {
      newErrors.blogEmail = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(blogEmail)) {
      newErrors.blogEmail = "Invalid email format";
    }

    if (!setBlogWebsite) {
      newErrors.setBlogWebsite = "Website is required";
    }

    if (!blogMessage) {
      newErrors.blogMessage = "Your Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  return (
    <>
      {isLoading && <Loader />}
      <form onSubmit={onBlogComment} class="mt-4">
        <div class="row">
          <div class="col-sm-6">
            <input
              type="text"
              placeholder="Enter Name"
              className="contact-field blogcomment"
              value={blogName}
              onChange={(e) => setBlogName(e.target.value)}
              required
            />
            {errors.blogName && (
              <span className="text-danger">{errors.blogName}</span>
            )}
          </div>
          <div class="col-sm-6">
            <input
              type="email"
              placeholder="Enter Email Address"
              className="contact-field blogcomment"
              value={blogEmail}
              onChange={(e) => setBlogEmail(e.target.value)}
              required
            />
            {errors.blogEmail && (
              <span className="text-danger">{errors.blogEmail}</span>
            )}
          </div>
          <div class="col-sm-6">
            <input
              type="text"
              placeholder="Website"
              className="contact-field blogcomment"
              value={blogWebsite}
              onChange={(e) => setBlogWebsite(e.target.value)}
              required
            />
            {errors.blogWebsite && (
              <span className="text-danger">{errors.blogWebsite}</span>
            )}
          </div>
          <div class="col-sm-12">
            <textarea
              type="text"
              placeholder="Enter Your Comment"
              rows={4}
              className="contact-field blogcomment"
              value={blogMessage}
              onChange={(e) => setBlogMessage(e.target.value)}
              required
            />{" "}
            {errors.blogMessage && (
              <span className="text-danger">{errors.blogMessage}</span>
            )}
          </div>
        </div>
        <div class="">
          <button class="button bg-green border-0" aria-labelledby="comment">Post Comment</button>
        </div>
      </form>
    </>
  );
};

export default BlogComment;
