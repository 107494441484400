import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { Config } from "../../Config";
const BlogCard = ({ blogs = [] }) => {
  const sliderRef = useRef(null);
  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="row mt-5">
        <Slider ref={sliderRef} {...blogsSettings}>
          {blogs.map((blog, i) => {
            return (
              <div key={i}>
                <div className="blog-card">
                  <div>
                    <img
                      src={`${Config.imageUrl}blogs/${blog.main_image}`}
                      alt="blog"
                      loading="lazy"
                    />
                  </div>

                  <div className="blog-content">
                    <strong className="pt-4 d-block text-slik fs-5">
                      {moment(blog.created_at).format("MMMM D, YYYY")}
                    </strong>

                    <h4 className="my-2 fw-bold text-dark">{blog.title}</h4>

                    <p className="paragraph mt-3 mb-4">
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: blog.content.slice(0, 105),
                        }}
                      />
                    </p>

                    <Link
                      to={`${Config.appUrl}blog/${blog.slug}`}
                      className="text-center"
                    >
                      Read More
                      <i className="bi bi-arrow-right ms-2 arrow-animation"></i>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default BlogCard;
