import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../Assets/Css/About.css";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";
import BlogComment from "./BlogComment";
const BlogDetails = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  document.title = "Blog Details | Rajveer Impex";
  const params = useParams();
  useEffect(() => {
    onBlog();
    onBlogDetail();
  }, [params]);
  const [isLoading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const onBlog = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}blogdetail/${params.slug}`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.blog);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const onBlogDetail = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}blog`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setBlogs(response.data.blog_page.blog);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const onInquiry = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);

    try {
      const response = await axios({
        method: "post",
        url: `${Config.apiUrl}blog_contact`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (validateForm()) {
          swal({
            icon: "success",
            text: "Thank You! Product Inquiry Sent Successfully",
          }).then((data) => {
            window.location.reload();
            setLoading(false);
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "First name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!phone) {
      newErrors.phone = "Phone number is required";
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">{data.title}</h1>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-green text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              {data.title}
            </p>
          </div>
        </div>
      </section>
      <section className="py-5 bg-light-blue">
        <div className="container-box pb-md-5 pt-md-4">
          <div className="row">
            <div className="col-md-8 ps-md-4">
              <div>
                <img
                  src={`${Config.imageUrl}blogs/${data.main_image}`}
                  style={{
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  className="w-100 rounded"
                  alt="blog"
                  loading="lazy"
                />
              </div>
              <div className="mt-4">
                <h3 className="blog-title">{data.title}</h3>
                <p className="paragraph mt-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />
                </p>
              </div>
              <div className="comment-box mt-5">
                <h3>Leave a Reply </h3>
                <p class="note">
                  Your email address will not be published. Required fields are
                  marked *
                </p>
                <BlogComment />
              </div>
            </div>
            <div className="col-md-4" id="blog-post">
              <h3 className="sidebar-widget-title">Popular Blog Posts</h3>
              {blogs.slice(0, 5).map((blog, i) => {
                return (
                  <div className="sidebar-widget mb-20" key={i}>
                    <div className="sidebar-thumb">
                      <Link to={`${Config.appUrl}blog/${blog.slug}`}>
                        <div className="post__img">
                          <img
                            className="post-recent"
                            alt="recent post"
                            src={`${Config.imageUrl}blogs/${blog.main_image}`}
                            loading="lazy"
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="sidebar-text">
                      <h4 className="sidebar-title">
                        <Link
                          to={`${Config.appUrl}blog/${blog.slug}`}
                          className="post-title"
                        >
                          <p>{blog.title}</p>
                        </Link>
                      </h4>
                      <span>
                        {moment(blog.created_at).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="p-4 rounded bg-white mt-5">
                <h4 className="fw-bold text-dark-blue mb-4">Connect With Us</h4>
                <form onSubmit={onInquiry} className="mt-4 contact-form">
                  <div className="mt-3">
                    <label htmlFor="">
                      {" "}
                      Name <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="contact-field"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label htmlFor="">
                      {" "}
                      Email Address <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      className="contact-field"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label htmlFor="">
                      {" "}
                      Phone Number <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className="contact-field"
                      value={phone}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) event.preventDefault();
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    {errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label htmlFor="">
                      {" "}
                      Message <span className="text-danger">*</span>{" "}
                    </label>
                    <textarea
                      type="text"
                      placeholder="Enter Your Query"
                      rows={4}
                      className="contact-field"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>

                  <div className="mt-4 ">
                    <button className="button bg-green border-0" aria-labelledby="message">
                      <i className="bi bi-send me-3"></i>
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
