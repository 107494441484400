import React, { useEffect, useRef, useState } from "react";

import "../../Assets/Css/Home.css";
import { Form, Link, useParams } from "react-router-dom";
import ProductForm from "./ProductForm";
import { Col, Modal, Row } from "reactstrap";
import ReactPaginate from "react-paginate";
import InquiryForm from "./InquiryForm";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";

const Daiper = () => {
  const sliderRef = useRef();
  const [page, setPage] = useState(1);
  const params = useParams();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    onSubcategory();
  }, [params]);

  const formattedSlug = params.slug.replace(/-/g, " ").toLowerCase();

  const capitalizedSlug =
    formattedSlug.charAt(0).toUpperCase() + formattedSlug.slice(1);
  document.title = `${capitalizedSlug} | Rajveer Impex`;

  const [enquiry_modal, setenquiry_modal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState([]);
  const [product, setProduct] = useState([]);
  const onSubcategory = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}subcategory/${params.slug}`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setSubcategoryName(response.data.subcategory_name);
        setProduct(response.data.product);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (selected === Math.ceil(product.length / 10) - 1) {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">{subcategoryName}</h1>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-dark text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              {subcategoryName}
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container-box py-md-4">
          <div className="row">
            <div className="col-md-4">
              <div
                className="p-4 bg-white"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <h4 className="fw-bold mb-4">CATEGORY</h4>
                <button className="bg-light text-slik border-0 w-100 text-start fs-5 py-2 px-3 border-bottom" aria-labelledby="message">
                  {subcategoryName}
                </button>
              </div>

              <div
                className="p-4 bg-white mt-4 bg-productinquiry"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <ProductForm />
              </div>
            </div>

            <div className="col-md-8" id="filter-product">
              <div
                className="p-4 bg-white"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <div className="mb-5">
                  <div className="row">
                    {product.slice(page * 10 - 10, page * 10).map((obj, i) => {
                      console.log(product.slice(page * 10 - 10, page * 10));
                      return (
                        <div className="col-md-4" id="product-image" key={i}>
                          <Link
                            to={`${Config.appUrl}product-details/${obj.slug}`}
                            className="text-dark text-decoration-none"
                            onClick={(e) => {
                              if (obj.is_feature == 1) {
                                e.preventDefault();
                                setenquiry_modal(true);
                              }
                            }}
                          >
                            <div className="product-card text-center mt-4">
                              <img
                                src={`${Config.imageUrl}product/featured/${obj.feature_image}`}
                                className="w-75"
                                alt="product"
                                loading="lazy"
                              />
                              <div className="mt-3 px-2 py-3 px-3">
                                <h5 className="fw-bold">{obj.title}</h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  {product.length >= 10 && (
                    <div className="d-flex pagination new-product pt-4 justify-content-end">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(product.length / 10)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="p-4 bg-white mt-5 product-inquiry"
                style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px" }}
              >
                <ProductForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={enquiry_modal} size="lg" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Inquiry Form</h5>
          <button
            type="button"
            aria-labelledby="message"
            onClick={() => {
              setenquiry_modal(false);
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <InquiryForm />
      </Modal>
    </>
  );
};

export default Daiper;
