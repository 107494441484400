export const Config = {
  // Live
  appUrl: "/",
  apiUrl: "https://rajveerimpex.com/admin/api/",
  imageUrl: "https://rajveerimpex.com/admin/assets/front/img/",

  // ZTPL 
  // appUrl: "/rajveerimpex/",
  // apiUrl: "https://www.ztpl.net/rajveerimpex/backend/api/",
  // imageUrl: "https://www.ztpl.net/rajveerimpex/backend/assets/front/img/",
};
