import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../Assets/Css/About.css";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";

const Blogs = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  document.title = "All Blogs | Rajveer Impex";

  useEffect(() => {
    onBlog();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const onBlog = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}blog`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setBlogs(response.data.blog_page.blog);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">Our Blogs</h1>
            <p className="mt-4 header-path-link">
              <Link
                to="/glamour-decor"
                className="text-green text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Our Blogs
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light-blue">
        <div className="container-box pb-md-5 pt-md-4">
          <div>
            <h2 className="head-2">Our Latest Blog Post</h2>
            <p className="paragraph mb-0">
              Discover the intricacies of import-export in the medical product
              industry in our latest blog post. From regulatory hurdles to
              supply chain logistics, we navigate the complexities of global
              trade. Gain valuable insights to optimize operations and ensure
              compliance with quality standards.
            </p>
          </div>
          <div className="row">
            {blogs.map((blog, i) => {
              return (
                <div key={i} className="col-md-4 mt-5">
                  <div className="blog-card">
                    <div>
                      <img
                        src={`${Config.imageUrl}blogs/${blog.main_image}`}
                        alt="blog"
                        loading="lazy"
                      />
                    </div>

                    <div className="blog-content">
                      <strong className="pt-4 d-block fs-5">
                        {moment(blog.created_at).format("MMMM D, YYYY")}
                      </strong>

                      <h4 className="my-2 fw-bold text-dark-blue">
                        {blog.title}
                      </h4>

                      <p className="paragraph mt-3 mb-4">
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: blog.content.slice(0, 105),
                          }}
                        />
                      </p>

                      <Link
                        to={`${Config.appUrl}blog/${blog.slug}`}
                        className="d-block bg-green button text-center text-white"
                      >
                        Read More
                        <i className="bi bi-arrow-right ms-2 arrow-animation"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
