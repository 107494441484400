import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Config } from "../../Config";
import Slider from "react-slick";
const CarouselSlider = ({ homeBanner = [] }) => {
  const sliderRef = useRef();
  const bannerSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="banner-section">
        <div className="">
          <div className="row p-0">
            <div className="col-md-12 p-0" id="new-banner">
              <Slider {...bannerSettings}>
                {homeBanner.map((obj, i) => {
                  return (
                    <div key={i}>
                      <div className="">
                        <img
                          src={`${Config.imageUrl}sliders/${obj.image}`}
                          className="w-100 home-banner"
                          loading="lazy"
                          alt="banner"
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarouselSlider;
