import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../Assets/Css/About.css";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Home Page/Loader";
import { Config } from "../../Config";
const About = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  document.title = "Contact Us | Rajveer Impex";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [product, setProduct] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    product: "",
    message: "",
  });
  const onInquiry = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("product", product);
    formData.append("message", message);

    try {
      const response = await axios({
        method: "post",
        url: `${Config.apiUrl}contact`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (validateForm()) {
          swal({
            icon: "success",
            text: "Thank You! Product Inquiry Sent Successfully",
          }).then((data) => {
            window.location.reload();
            setLoading(false);
            setName("");
            setEmail("");
            setPhone("");
            setProduct("");
            setMessage("");
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "First name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!phone) {
      newErrors.phone = "Phone number is required";
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!product) {
      newErrors.product = "Product is required";
    }

    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onContactDetail();
  }, []);
  const [contactTitle, setContacTitle] = useState([]);
  const [contactSubtitle, setContactSubtitle] = useState([]);
  const [contactAddress, setContactAddress] = useState([]);
  const [contactPhone, setContactPhone] = useState([]);
  const [contactEmail, setContactEmail] = useState([]);
  const [contactMap, setContactMap] = useState([]);
  const [longitude, setLongitude] = useState([]);
  const [courseSubtitle, setCourseTitle] = useState([]);
  const [knowledgebaseSubtitle, setKnowTitle] = useState([]);
  const onContactDetail = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}contact_detail`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setContacTitle(
          response?.data?.contact_page?.contact_datail?.contact_form_title
        );
        setContactSubtitle(
          response?.data?.contact_page?.contact_datail?.contact_form_subtitle
        );
        setContactAddress(
          response?.data?.contact_page?.contact_info?.contact_addresses
        );
        setContactPhone(
          response?.data?.contact_page?.contact_info?.contact_numbers
        );
        setContactEmail(
          response?.data?.contact_page?.contact_info?.contact_mails
        );
        setContactMap(response?.data?.contact_page?.contact_info?.latitude);
        setLongitude(response?.data?.contact_page?.contact_info?.longitude);
        setCourseTitle(
          response?.data?.contact_page?.contact_info?.course_subtitle
        );
        setKnowTitle(
          response?.data?.contact_page?.contact_info?.knowledgebase_subtitle
        );
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  const [subc, setSubc] = useState([]);
  const onSub = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}product_subcategory`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        console.log(response);
        setSubc(response.data.subc);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    onSub();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box d-flex justify-content-center align-items-end">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4 mt-4 pt-1">Contact Us</h1>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-dark text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Contact Us
            </p>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box pt-md-4">
          <div className="row">
            <div className="col-md-12 ps-md-5">
              <div>
                <h2 className="head-2">{contactTitle}</h2>
                <p className="paragraph">{contactSubtitle}</p>
                <div className="py-4 row" id="direction-flex">
                  <div className=" col-md-3 text-center  pb-4">
                    <div className="contact-box">
                      <i className="bi bi-geo-alt fs-4 text-slik"></i>
                      <div className="">
                        <h5 className="fw-bold text-dark-blue">
                          Administrative
                        </h5>
                        <p className="mb-0 paragraph ">{contactAddress}</p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3 text-center pb-4">
                    <div className="contact-box">
                      <i className="bi bi-geo-alt fs-4 text-slik"></i>
                      <div className="">
                        <h5 className="fw-bold text-dark-blue">Work</h5>
                        <p className="mb-0 paragraph ">{courseSubtitle}</p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3 text-center pb-4" id="contct-us">
                    <div className="contact-box">
                      <i className="bi bi-whatsapp fs-4 text-slik"></i>
                      <div className=" contact-email">
                        <h5 className="fw-bold text-dark-blue">
                          Whatsapp Number
                        </h5>
                        <a
                          href={`https://wa.me/${contactPhone}`}
                          className="paragraph text-decoration-none"
                          target="_blank"
                        >
                          +91 {contactPhone}
                        </a>
                        <br />
                        <a
                          href={`https://wa.me/${longitude}`}
                          className="paragraph text-decoration-none"
                          target="_blank"
                        >
                          +91 {longitude}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3 text-center pb-4">
                    <div className="contact-box">
                      <i className="bi bi-envelope-at fs-4 text-slik"></i>
                      <div className=" contact-email">
                        <h5 className="fw-bold text-dark-blue">
                          Email Address
                        </h5>
                        <a
                          href={`mailto:${contactEmail}`}
                          className="text-decoration-none paragraph "
                        >
                          {contactEmail}
                        </a>
                        <a
                          href={`mailto:${knowledgebaseSubtitle}`}
                          className="text-decoration-none paragraph "
                        >
                          {" "}
                          {knowledgebaseSubtitle}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-gray">
        <div className="container-box py-md-5">
          <div className="row py-5">
            <div className="col-md-6 pe-md-5">
              <div className="p-5 bg-white" id="contact-usform">
                <h2 className="text-dark-blue display-6 fw-bold">
                  You Say! We Listen
                </h2>

                <form onSubmit={onInquiry} className="mt-5 contact-form">
                  <div className="mt-4">
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="contact-field"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>
                  <div className="mt-4">
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      className="contact-field"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="mt-4">
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className="contact-field"
                      value={phone}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) event.preventDefault();
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    {errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )}
                  </div>
                  <div className="mt-4">
                    <select
                      className="contact-field"
                      value={product}
                      onChange={(e) => setProduct(e.target.value)}
                      required
                    >
                      <option>-- Select Product --</option>
                      {subc.map((sub, i) => {
                        return (
                          <option value={sub.name} key={i}>
                            {sub.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.product && (
                      <span className="text-danger">{errors.product}</span>
                    )}
                  </div>
                  <div className="mt-4">
                    <textarea
                      type="text"
                      placeholder="Enter Your Query"
                      rows={4}
                      className="contact-field"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>

                  <div className="mt-4">
                    <button className="button bg-green  border-0" aria-labelledby="message">
                      <i className="bi bi-send me-3"></i>
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <iframe
                  src={contactMap}
                  style={{ width: "100%" }}
                  height="711"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
