import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Home Page/Loader";
import swal from "sweetalert";
import axios from "axios";
import { Config } from "../../Config";
const Certificate = () => {
  document.title = "Certificate | Rajveer Impex";
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  useEffect(() => {
    onCertificate();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState([]);
  const onCertificate = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}certificate`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setCertificate(response.data.certificate_page.certificate);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white text-center">
            <h1 className="fw-bold display-4">Certificate</h1>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-dark text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              Certificate
            </p>
          </div>
        </div>
      </section>

      <section className="py-5 pt-5 bg-light" style={{ overflow: "hidden" }}>
        <div className="row">
          {certificate.map((certi, i) => {
            return (
              <div className="col-md-4 certificate-img" key={i}>
                <div className="mt-4">
                  <Link
                    to={`${Config.imageUrl}members/${certi.image}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${Config.imageUrl}members/${certi.image}`}
                      className="w-75 img-all"
                      alt="certificate"
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Certificate;
