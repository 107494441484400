import React, { useEffect, useRef, useState } from "react";
import "../../Assets/Css/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Loader from "./Loader";
import moment from "moment";
import { Config } from "../../Config";
import Client from "../Elements/Client";
import Certificate from "../Elements/Certificate";
import CarouselSlider from "./CarouselSlider";
import BlogCard from "./BlogCard";

const Home = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  document.title = "Home | Rajveer Impex";

  useEffect(() => {
    onHomePage();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogTitle, setBlogTitle] = useState([]);
  const [blogSubtitle, setBlogSubtitle] = useState([]);

  const [client, setClient] = useState([]);
  const [introTitle, setIntroTitle] = useState([]);
  const [introText, setIntroText] = useState([]);
  const [introBtn, setIntroBtn] = useState([]);
  const [introBg, setIntroBg] = useState([]);
  const [introBg2, setIntroBg2] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [certificateTitle, setCertificateTitle] = useState([]);
  const [certificateSubtitle, setCertificateSubtitle] = useState([]);
  const [productTitle, setProductTitle] = useState([]);
  const [productSubtitle, setProductSubitle] = useState([]);
  const [homeProduct, setHomeProduct] = useState([]);
  const [homeBanner, setHomeBanner] = useState([]);
  const onHomePage = async (e) => {
    setLoading(true);
    const formData = new FormData();
    try {
      const response = await axios({
        method: "get",
        url: `${Config.apiUrl}homepage`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setBlogs(response.data.home_page.blog);
        setBlogTitle(response.data.home_page.blog_section.blog_section_title);
        setBlogSubtitle(
          response.data.home_page.blog_section.blog_section_subtitle
        );
        setHomeBanner(response.data.home_page.banner);
        setClient(response.data.home_page.client);
        setIntroTitle(
          response.data.home_page.intro_section.intro_section_title
        );
        setIntroText(response.data.home_page.intro_section.intro_section_text);

        setIntroBtn(
          response.data.home_page.intro_section.intro_section_button_text
        );
        setIntroBg(response.data.home_page.intro_section.intro_bg);
        setIntroBg2(response.data.home_page.intro_section.intro_bg2);
        setCertificate(response.data.home_page.certificate);
        setCertificateTitle(
          response.data.home_page.certificate_section.certificate_title
        );
        setCertificateSubtitle(
          response.data.home_page.certificate_section.certificate_subtitle
        );
        setProductTitle(
          response.data.home_page.product_section.portfolio_section_title
        );
        setProductSubitle(
          response.data.home_page.product_section.portfolio_section_text
        );
        setHomeProduct(response.data.home_page.home_product);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <CarouselSlider homeBanner={homeBanner} />
      {/* about section */}
      <section className="py-5 bg-about-section">
        <div className="container-box py-md-5">
          <div className="row align-items-center about-section">
            <div className="col-md-6">
              <h2 className="mb-4">{introTitle}</h2>

              <div
                dangerouslySetInnerHTML={{
                  __html: introText,
                }}
              />

              <div className="mt-5">
                <Link
                  to={`${Config.appUrl}about-us`}
                  className="button bg-green text-light"
                >
                  {introBtn}
                </Link>
              </div>
            </div>

            <div className="col-md-6 ps-md-5 text-end position-relative">
              <div className="loading-anime" style={{ height: "400px" }}>
                <img
                  src={`${Config.imageUrl}${introBg}`}
                  className="w-100 h-100"
                  loading="lazy"
                  alt="introduction"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-box pb-5 pt-5">
          <div className="top-heading">
            <h5 className="fw-bold text-dark">Our Relations</h5>
            <h2 style={{ fontSize: "49px" }}>We Define Our Relationship</h2>
          </div>
          <Client client={client} />
        </div>
      </section>

      {/* Products */}
      <section className="py-5 bg-light">
        <div className="container-box py-md-5">
          <div className="top-heading">
            <h5>{productTitle}</h5>
            <h2>{productSubtitle}</h2>
          </div>

          <div className="row">
            {homeProduct?.map((product, i) => {
              return (
                <div key={i} className="col-md-3">
                  <Link
                    to={`${Config.appUrl}${product.slug}`}
                    className="text-dark text-decoration-none"
                  >
                    <div className="product-card text-center mt-5">
                      <img
                        src={`${Config.imageUrl}product/${product.image}`}
                        className="w-75"
                        alt="product"
                        loading="lazy"
                      />
                      <div className="mt-3 px-2 py-3 px-3">
                        <h5 className="fw-bold">{product.name}</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* blogs */}
      <section className="py-5 ">
        <div className="container-box py-md-5">
          <div className="top-heading">
            <h5>{blogTitle}</h5>
            <h2>{blogSubtitle}</h2>
          </div>
          <BlogCard blogs={blogs} />
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-5 bg-gray">
        <div className="container-box py-md-5">
          <div className="top-heading">
            <h5>{certificateTitle}</h5>
            <h2>{certificateSubtitle}</h2>
            <h2></h2>
          </div>
          <Certificate certificate={certificate} />
        </div>
      </section>
    </>
  );
};

export default Home;
